<template>
  <div class="tw-my-8 boxed__container">
    <p class="tw-text-2xl tw-mt-12 tw-font-bold tw-text-center">
      Photo Gallery
    </p>
    <div class="tw-w-full  tw-px-8 md:tw-px-16">
      <div>
        <div class="tw-flex tw-flex-wrap">
          <img
            v-for="(image, i) in randomImage"
            :key="i"
            class="tw-w-full md:tw-w-1/4 tw-h-64 tw-object-cover tw-p-1"
            :src="image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const shuffle = require("shuffle-array");
export default {
  computed: {
    randomImage() {
      return shuffle(this.images);
    },
  },
  data() {
    return {
      images: [
        "https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1580752109/Pamilerin/WhatsApp_Image_2020-01-14_at_19.02.32.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1580752108/Pamilerin/WhatsApp_Image_2020-01-14_at_19.02.34.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1580752108/Pamilerin/WhatsApp_Image_2020-01-14_at_19.02.33.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1580752107/Pamilerin/WhatsApp_Image_2020-01-14_at_19.01.09.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1580752108/Pamilerin/WhatsApp_Image_2020-01-14_at_19.01.14.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1580752108/Pamilerin/WhatsApp_Image_2020-01-14_at_19.01.08.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1580752108/Pamilerin/WhatsApp_Image_2020-01-14_at_19.01.06.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1580752108/Pamilerin/WhatsApp_Image_2020-01-14_at_19.01.07.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1580752107/Pamilerin/WhatsApp_Image_2020-01-14_at_19.00.55.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1580752107/Pamilerin/WhatsApp_Image_2020-01-14_at_19.00.56_1.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1581202177/Pamilerin/WhatsApp_Image_2020-02-04_at_22.25.47.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1611875786/MOJ/WhatsApp_Image_2021-01-12_at_3.50.10_PM_1.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1611875786/MOJ/WhatsApp_Image_2021-01-12_at_3.50.09_PM_1.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1611875786/MOJ/WhatsApp_Image_2021-01-12_at_3.50.10_PM.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1611875785/MOJ/WhatsApp_Image_2021-01-12_at_3.50.19_PM.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1611875786/MOJ/WhatsApp_Image_2021-01-12_at_3.50.18_PM.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1611875785/MOJ/WhatsApp_Image_2021-01-12_at_3.50.11_PM.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1614628339/Pamilerin/WhatsApp_Image_2021-02-25_at_9.42.27_PM.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1614628339/Pamilerin/WhatsApp_Image_2021-02-25_at_9.42.26_PM.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1614628339/Pamilerin/WhatsApp_Image_2021-02-25_at_9.37.53_PM_2.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1614628339/Pamilerin/WhatsApp_Image_2021-02-25_at_9.37.54_PM.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1614628338/Pamilerin/WhatsApp_Image_2021-02-25_at_9.37.53_PM.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1614628338/Pamilerin/WhatsApp_Image_2021-02-25_at_9.37.53_PM_1.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1614628338/Pamilerin/WhatsApp_Image_2021-02-25_at_9.37.52_PM_2.jpg",
        "https://res.cloudinary.com/dldd8ucby/image/upload/v1614628337/Pamilerin/WhatsApp_Image_2021-02-25_at_9.37.50_PM.jpg",
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
