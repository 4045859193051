<template>
  <div class="boxed__container tw-flex tw-flex-wrap" id="about-us">
    <div
      class="
        tw-w-full
        md:tw-w-1/2
        about-text
        tw-text-white tw-px-8
        md:tw-px-16
        tw-py-16
      "
    >
      <p class="tw-text-2xl tw-font-bold">About Us</p>

      <p>
        Pamilerin Ayo Widow’s Foundation is a private, voluntary, non-profit,
        non- government organization but a faith based, focused on alleviating
        the burden and poverty of depressed widows in the society. The burden to
        start this Foundation started in 2011 during my post graduate study in
        United Kingdom. Widowhood in Nigeria is a great Challenge because there
        is no adequate provision for widows from Government, Church and Private
        organization. Some of them are vulnerable in term of health challenge,
        social-economic problem and the worst is lack of access to late
        husband’s property. The Nigeria economy is not friendly to poor widows
        and this has to be address through a non- government organization like
        Pamilerin Ayo Widows Foundation.
      </p>

      <p class="tw-text-2xl tw-font-bold tw-mt-16">What we do</p>

      <ul>
        <li>To give adequate counsel to depressed widows</li>
        <li>To assist and empower in fulfiling their life's goals</li>
        <li>To help toward, Economic, Social and Emotional Independence</li>
      </ul>
      <br />
      <router-link
        to="/about"
        class="
          tw-bg-orange-500 tw-text-white tw-p-2 tw-mx-2 tw-rounded tw-float-left
        "
        >Learn More</router-link
      >
    </div>

    <img
      class="tw-w-full md:tw-w-1/2 tw-object-cover"
      src="https://res.cloudinary.com/dldd8ucby/image/upload/v1580752107/Pamilerin/WhatsApp_Image_2020-01-14_at_19.00.56.jpg"
    />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.about-text {
  background-color: #fff;
  color: #141720;
}
image {
  height: 200px;
}

.v-application a {
  color: white;
}
</style>
