<template>
  <div>
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
  name: "App",

  components: {
    Header,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
div {
  font-family: "Cabin", sans-serif;
}

.boxed__container {
  max-width: 1350px !important;
  margin: 0 auto;
}
</style>
