<template>
  <div
    class="tw-font-sans tw-antialiased tw-fixed tw-w-full my-header tw-z-10 elevation-1"
    id="app"
  >
    <main class="boxed__container">
      <nav
        class="tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-py-4 tw-px-8"
      >
        <div
          class="tw-flex tw-items-center tw-flex-no-shrink tw-text-white tw-mr-6"
        >
          <router-link to="/">
            <div
              class="tw-flex tw-items-center tw-flex-no-shrink tw-text-white tw-mr-6"
            >
              <img
                class="inline m-1 tw-h-16"
                src="https://res.cloudinary.com/dldd8ucby/image/upload/v1581173977/Pamilerin/pamilerin_2.png"
              />
              <span
                class=" tw-ml-4 tw-font-semibold tw-text-2xl tw-text-white md:tw-px-4 md:tw-py-2 lg:tw-mt-0 tw-mr-4"
                >Pamilerin Ayo</span
              >
            </div>
          </router-link>
        </div>
        <div class="tw-block sm:tw-hidden">
          <button
            @click="toggle"
            class="tw-flex tw-items-center tw-px-3 tw-py-2 tw-border tw-rounded tw-text-teal-lighter tw-border-teal-light"
          >
            <svg
              class="tw-fill-current tw-h-3 tw-w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          :class="open ? 'tw-block' : 'tw-hidden'"
          class="tw-w-full tw-flex-grow sm:tw-flex sm:tw-items-center sm:tw-w-auto"
        >
          <div class="tw-text-sm sm:tw-flex-grow"></div>
          <div>
            <router-link
              to="/about"
              class="tw-block tw-mt-4 lg:tw-inline-block tw-uppercase tw-font-semibold tw-text-lg md:tw-px-4  lg:tw-mt-0 tw-mr-4"
              >About Us</router-link
            >
            <!-- <router-link
            to="/#gallery"
            class="tw-block tw-mt-4 lg:tw-inline-block tw-uppercase tw-font-semibold tw-text-lg md:tw-px-4  lg:tw-mt-0 tw-mr-4"
            >Gallery</router-link
          > -->
            <a
              href="https://dashboard.flutterwave.com/donate/imnckwyj17yq"
              target="_blank"
              class="tw-block tw-mt-4 lg:tw-inline-block tw-uppercase tw-font-semibold tw-text-lg md:tw-px-4  lg:tw-mt-0 tw-mr-4"
              >Donate</a
            >
          </div>
        </div>
      </nav>
    </main>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggle() {
      console.log(this.open);
      this.open = !this.open;
    },
    showModal() {
      this.$swal({ titleText: "Care Centers" });
    },
  },
};
</script>

<style scoped>
.my-header {
  color: white;
  background-color: #141720;
  z-index: 100;
}
.v-application a {
  color: white;
}
</style>
