<template>
  <div>
    <Landing />
    <About id="gallery" />
    <div></div>
    <Gallery />
  </div>
</template>

<script>
import Landing from "../components/Landing";
import About from "../components/About";
import Gallery from "../components/Gallery";
export default {
  name: "App",

  components: {
    Landing,
    About,
    Gallery
  },

  data: () => ({
    //
  })
};
</script>

<style>
div {
  font-family: "Cabin", sans-serif;
}
</style>
