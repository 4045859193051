<template>
  <div>
    <agile
      class="body"
      :nav-buttons="false"
      :autoplay-speed="3500"
      :speed="1500"
      fade
      autoplay
    >
      <div class="slide slide1">
        <div class="slide-content">
          <h1 class="slider-title">Pamilerin Ayo Widows Foundation</h1>
        </div>
      </div>
      <div class="slide slide2">
        <div class="slide-content">
          <h1 class="slider-title">Pamilerin Ayo Widows Foundation</h1>
        </div>
      </div>
    </agile>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
export default {
  data() {
    return {};
  },
  components: {
    agile: VueAgile
  }
};
</script>

<style scoped>
.body {
  margin: 0 !important;
  padding: 0 !important;
}
.slide1 {
  background: linear-gradient(
      89.81deg,
      #111111 0.05%,
      rgba(17, 17, 17, 0.65) 41.25%
    ),
    url("https://res.cloudinary.com/dldd8ucby/image/upload/v1580752108/Pamilerin/WhatsApp_Image_2020-01-14_at_19.01.08.jpg");
  background-size: cover;
  min-height: 100vh;
}
.slide2 {
  background: linear-gradient(
      89.81deg,
      #111111 0.05%,
      rgba(17, 17, 17, 0.65) 41.25%
    ),
    url("https://res.cloudinary.com/dldd8ucby/image/upload/v1580752107/Pamilerin/WhatsApp_Image_2020-01-14_at_19.01.04.jpg");
  background-size: cover;
  min-height: 100vh;
}
h3 {
  text-align: center;
  margin: 100px;
  color: #000;
}
.slide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}
.slider-title {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 77px; /* identical to box height */
  color: #ffffff;
}
.slider-subtitle {
  font-family: Lato;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}
</style>
