<template>
  <div class="my-footer tw-px-2 md:tw-px-16">
    <main class="boxed__container">
      <div class="tw-p-4 tw-flex tw-flex-wrap">
        <div class="tw-w-full md:tw-w-1/3">
          <div class="md:tw-p-8">
            <img
              class="inline m-1 tw-h-16"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/v1581173977/Pamilerin/pamilerin_2.png"
            />
            >
          </div>
        </div>
        <div class="tw-w-full md:tw-w-1/3">
          <div class="md:tw-p-8 tw-text-white">
            <router-link
              to="/about"
              class="
                tw-block
                md:tw-my-3
                lg:tw-inline-block
                tw-uppercase tw-font-semibold tw-text-lg
                md:tw-px-4
                lg:tw-mt-0
                tw-mr-4
              "
              >About Us</router-link
            ><br />
            <a
              href="https://dashboard.flutterwave.com/donate/imnckwyj17yq"
              target="_blank"
              class="
                tw-block tw-mt-4
                lg:tw-inline-block
                tw-uppercase tw-font-semibold tw-text-lg
                md:tw-px-4
                lg:tw-mt-0
                tw-mr-4
              "
              >Donate</a
            >
            <!-- <router-link
            to="/#gallery"
            class="tw-block md:tw-my-3 lg:tw-inline-block tw-uppercase tw-font-semibold tw-text-lg md:tw-px-4  lg:tw-mt-0 tw-mr-4"
            >Gallery</router-link
          > -->
            <br />
          </div>
        </div>
        <div class="tw-w-full md:tw-w-1/3">
          <div class="md:tw-p-8">
            <p class="tw-text-left tw-text-white">
              7 Abbi Street, Mende Maryland, Lagos
            </p>
            <p class="tw-text-left tw-text-white">08034538413</p>
            <p class="tw-text-left tw-text-white">
              pamilerinayofoundation@gmail.com
            </p>
            <p class="tw-text-left tw-text-white">
              &copy; Pamilerin Ayo Widows Foundation. 2020<br />All rights
              reserved
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.my-footer {
  background-color: #141720;
  padding: 12rem auto;
}
.v-application a {
  color: white;
}
</style>
