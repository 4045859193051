<template>
  <div class="">
    <div class="tw-mt-12 tw-flex boxed__container tw-flex-wrap" id="about-us">
      <div
        class="
          tw-w-full
          md:tw-w-1/2
          about-text
          tw-text-white tw-px-8
          md:tw-px-16
          tw-py-16
        "
      >
        <p class="tw-text-2xl tw-font-bold">About Us</p>
        <p>
          Pamilerin Ayo Widow’s Foundation is a private, voluntary, non-profit,
          non- government organization but a faith based, focused on alleviating
          the burden and poverty of depressed widows in the society. The burden
          to start this Foundation started in 2011 during my post graduate study
          in United Kingdom. Widowhood in Nigeria is a great Challenge because
          there is no adequate provision for widows from Government, Church and
          Private organization. Some of them are vulnerable in term of health
          challenge, social-economic problem and the worst is lack of access to
          late husband’s property. The Nigeria economy is not friendly to poor
          widows and this has to be address through a non- government
          organization like Pamilerin Ayo Widows Foundation.
        </p>

        <p class="tw-text-2xl tw-font-bold">ESTABLISHMENT</p>
        <p>
          Pamilerin Ayo Widows Foundation was registered on the 11th September
          2019 under corporate affairs commission (Federal Republic of Nigeria)
          and formally launched in the same year.
        </p>
      </div>

      <img
        class="
          tw-w-full
          md:tw-w-1/2
          my-image
          tw-px-8
          md:tw-px-16
          tw-py-16 tw-rounded-t-full
        "
        src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red/v1581160418/Pamilerin/WhatsApp_Image_2020-02-03_at_22.17.16.jpg"
      />
    </div>

    <div class="tw-bg-gray-200">
      <div class="tw-px-8 md:tw-px-16 tw-py-8 boxed__container">
        <p class="tw-text-2xl tw-font-bold">OUR OBJECTIVES</p>
        <p>The objectives of PAWIF include:</p>
        <p>
          Promoting the welfare and economic empowerment of disadvantaged widows
          and to support the education of their children and many children has
          benefited from this gesture. In other for widows to enjoy and feel
          equal in the society, PAWIF sets to improve living conditions of the
          widows and their dependent Children, through promoting income
          generation activities and projects, including empowerment, training,
          increasing the general awareness. PAWIF provides legal aid to women,
          through legal awareness and rendering legal assistance to widows
          involved in inheritance and child-guardianship disputes, and victims
          of human rights violation and denial.
        </p>

        <p class="tw-text-2xl tw-font-bold">THE TEAM MEMBERS</p>
        <p>
          Pamilerin Ayo Widow’s Foundation has a board known as trustee members
          comprising of eminently qualified people of integrity, highly
          professional excellence and respected Nigerians committed to lives
          transformation under her able leadership of Rev’d Dr. Emmanuel Ojo.
          Rev’d Dr. Emmanuel is a Senior Pastor of Christ the Rock Baptist
          Church Mende, and adjunct lecturer in Old Testament at Baptist College
          of Theology Lagos. He is the Moderator of Gideon One Baptist
          Association of Lagos East Baptist Conference.
        </p>
      </div>
    </div>

    <!-- <main class="boxed__container">
      <div
        class="
          tw-w-full
          mx-auto
          md:tw-w-1/2
          about-text
          tw-text-white tw-px-8
          md:tw-px-16
          tw-py-16
        "
      >
        <p class="tw-text-2xl tw-font-bold text-center">
          Our Board of Directors
        </p>
        <img
          class="tw-w-full"
          src="https://res.cloudinary.com/dldd8ucby/image/upload/v1611875787/MOJ/WhatsApp_Image_2021-01-12_at_3.50.09_PM.jpg"
        />
      </div>
    </main> -->

    <div class="tw-bg-gray-200">
      <div class="tw-px-8 md:tw-px-16 tw-py-8 boxed__container">
        <p class="tw-text-2xl tw-font-bold">VOICELESS VOICE</p>
        <p>
          PAWIF listens and hears the stories of the depressed widows with the
          mind of impacting them positively. Some have been socially cut off
          from their society, while some are facing challenges of troublesome
          child. Through counseling and prayers there are testimonies from
          selected few of the individuals whose lives the FAWIF has been
          privileged to transform.
        </p>

        <p class="tw-text-2xl tw-font-bold">SMALL SCALE PROJECT/MICRO LOAN</p>
        <p>
          PAWIF works with local Cooperative society and Micro –Finance Bank to
          obtain loan without interest to start a new business and support the
          existing one. This has transformed and alleviates poverty among the
          widows. Notwithstanding, the current economy hardship is affecting
          widows with children in the school. Their meager income cannot sustain
          them with the current situation. Therefore appeal is going to
          individuals and cooperate organization to come to our aids.
        </p>

        <p class="tw-text-2xl tw-font-bold">PREVIOUS BENEFICIARIES</p>
        <p>
          PAWIF has directly impacted the lives of more than 250 Widows within
          few years of establishment across the Federation especially Lagos
          State.
        </p>

        <p class="tw-text-2xl tw-font-bold">DONATIONS/SUPPORTS</p>
        <p>
          PAWIF receives donations from the board members, religion
          organization, government agencies, individuals and cooperate
          organization. Donation is open to all credible Nigerians and
          International organization in cash and kinds.
        </p>
        <p class="tw-text-xl tw-font-bold italic">ACCOUNT DETAILS</p>

        <p><span>Name:</span> <span>PAMILERIN AYO WIDOWS FOUNDATION</span></p>
        <p><span>Bank:</span> <span>GUARANTEE TRUST BANK, ANTHONY </span></p>
        <p><span>Number:</span> <span>0532244450</span></p>
      </div>
    </div>

    <Gallery />
  </div>
</template>

<script>
import Gallery from "../components/Gallery";
export default {
  components: {
    Gallery,
  },
};
</script>

<style scoped>
.about-text {
  background-color: #fff;
  color: #141720;
}

/* .my-image {
  height: 34rem;
} */

.v-application a {
  color: white;
}
</style>
